import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Post from '../components/Post';
import SEO from '../components/seo';

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark;
        const siteTitle = this.props.data.site.siteMetadata.title;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO htmlClass="weblog" title={post.frontmatter.title} description={post.excerpt} />
                <header>
                    <h1>Dispatch</h1>
                    <p>
                        By <Link to="/">Rowan Oulton</Link>
                    </p>
                </header>
                <Post html={post.html} slug={post.fields.slug} date={post.frontmatter.date} />
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            fields {
                slug
            }
            frontmatter {
                title
                date
            }
        }
    }
`;
